var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-sidebar" }, [
    _c("div", { staticClass: "layout-sidebar-backdrop" }),
    _vm._v(" "),
    _c("div", { staticClass: "layout-sidebar-body" }, [
      _c(
        "nav",
        { staticClass: "sidenav-collapse collapse", attrs: { id: "sidenav" } },
        [
          _c("ul", { staticClass: "sidenav" }, [
            _c("li", { staticClass: "sidenav-heading" }, [_vm._v("Menu")]),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "sidenav-item" },
              [
                _c("router-link", { attrs: { to: "/progetti" } }, [
                  _c("span", {
                    staticClass: "sidenav-icon icon icon-product-hunt",
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidenav-label" }, [
                    _vm._v("Progetti"),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "sidenav-item" },
              [
                _c("router-link", { attrs: { to: "/gruppi" } }, [
                  _c("span", { staticClass: "sidenav-icon icon icon-list" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidenav-label" }, [
                    _vm._v("Gruppi"),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "sidenav-item" },
              [
                _c("router-link", { attrs: { to: "/touchpoint" } }, [
                  _c("span", { staticClass: "sidenav-icon icon icon-th" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidenav-label" }, [
                    _vm._v("Touch Point"),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }