var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("canvas", {
      staticStyle: { position: "absolute", top: "-2000px" },
      attrs: { id: "dragimg" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "title-bar" }, [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "delete pull-left",
              on: {
                drop: function ($event) {
                  return _vm.removeItem($event)
                },
                dragover: function ($event) {
                  return _vm.dragover($event)
                },
              },
            },
            [
              _vm._v(
                "\n            Trascina i touch point qui per eliminarli dal modulo\n            "
              ),
              _c("i", { staticClass: "pull-left icon icon-2x icon-trash" }),
            ]
          ),
          _vm._v(" "),
          _vm.progetto.id != null
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-labeled pull-right",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.testo()
                    },
                  },
                },
                [_vm._m(0), _vm._v("\n            TESTO\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.progetto.id != null
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success btn-labeled pull-right m-r-sm",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.pdf()
                    },
                  },
                },
                [_vm._m(1), _vm._v("\n            PDF\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-info btn-labeled pull-right m-r-sm",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._m(2), _vm._v("\n            Salva\n        ")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn btn-default btn-labeled pull-right m-r-sm",
              attrs: { type: "button", to: "/progetti/" + _vm.path },
            },
            [
              _c("span", { staticClass: "btn-label" }, [
                _c("span", { staticClass: "icon icon-close icon-lg icon-fw" }),
              ]),
              _vm._v("\n            Chiudi\n        "),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "m-r-sm",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
                height: "35px",
                width: "135px",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.sposta ? "Modlità sposta" : "Modlità duplica") +
                  "\n            "
              ),
              _c("label", { staticClass: "switch switch-primary" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sposta,
                      expression: "sposta",
                    },
                  ],
                  staticClass: "switch-input",
                  attrs: { type: "checkbox", checked: "checked" },
                  domProps: {
                    checked: Array.isArray(_vm.sposta)
                      ? _vm._i(_vm.sposta, null) > -1
                      : _vm.sposta,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.sposta,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.sposta = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.sposta = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.sposta = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "switch-track" }),
                _vm._v(" "),
                _c("span", { staticClass: "switch-thumb" }),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "title-bar-title" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.progetto.nome,
              expression: "progetto.nome",
            },
          ],
          staticClass: "md-form-control p-nome",
          attrs: { type: "text" },
          domProps: { value: _vm.progetto.nome },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.progetto, "nome", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "progetto" }, [
      _c(
        "div",
        {
          staticClass: "palette",
          on: {
            drop: function ($event) {
              return _vm.removeItem($event)
            },
            dragover: function ($event) {
              return _vm.dragover($event)
            },
          },
        },
        [
          _vm._l(_vm.palette, function (g) {
            return _c("div", { key: g.id, staticClass: "group" }, [
              _c("div", { staticClass: "title collapsed" }, [
                _vm._v(_vm._s(g.nome)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "body collapsed" },
                [
                  _vm._l(g.gruppi, function (gg) {
                    return _c("div", { key: gg.id, staticClass: "group" }, [
                      _c("div", { staticClass: "title collapsed" }, [
                        _vm._v(_vm._s(gg.nome)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "body collapsed" }, [
                        gg.touchpoints.length > 0
                          ? _c(
                              "div",
                              { staticClass: "touchpoints-container" },
                              _vm._l(gg.touchpoints, function (t) {
                                return _c(
                                  "div",
                                  {
                                    key: t.id,
                                    staticClass: "touchpoints",
                                    attrs: { draggable: "true" },
                                    on: {
                                      dragstart: function ($event) {
                                        return _vm.drag($event, t, false)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", { attrs: { src: t.immagine } }),
                                    _vm._v(" "),
                                    _c("div", [_vm._v(_vm._s(t.nome))]),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  g.touchpoints.length > 0
                    ? _c(
                        "div",
                        { staticClass: "touchpoints-container" },
                        _vm._l(g.touchpoints, function (t) {
                          return _c(
                            "div",
                            {
                              key: t.id,
                              staticClass: "touchpoints",
                              attrs: { draggable: "true" },
                              on: {
                                dragstart: function ($event) {
                                  return _vm.drag($event, t, false)
                                },
                              },
                            },
                            [
                              _c("img", { attrs: { src: t.immagine } }),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(t.nome))]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                2
              ),
            ])
          }),
          _vm._v(" "),
          _vm.famiglie.length > 0
            ? _c("div", { staticClass: "group" }, [
                _c("div", { staticClass: "title collapsed" }, [
                  _vm._v("Famiglie"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "body collapsed" }, [
                  _c(
                    "div",
                    { staticClass: "touchpoints-container" },
                    _vm._l(_vm.famiglie, function (t) {
                      return _c(
                        "div",
                        {
                          key: t.id,
                          staticClass: "touchpoints",
                          attrs: { draggable: "true" },
                          on: {
                            dragstart: function ($event) {
                              return _vm.dragFamiglia($event, t)
                            },
                          },
                        },
                        [
                          _c("img", { attrs: { src: "/img/famiglia.png" } }),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(t.nome))]),
                          _vm._v(" "),
                          _c("div", { staticClass: "remove-famiglia" }, [
                            _c("i", {
                              staticClass: "icon icon-trash",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFamiglia(t, $event)
                                },
                              },
                            }),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "plano" }, [
        _c(
          "div",
          { staticClass: "scansia-tab-container" },
          [
            _vm._l(_vm.progetto.scansie, function (s, ix) {
              return _c(
                "div",
                {
                  key: s.id,
                  staticClass: "scansia-tab",
                  class: ix == _vm.current ? "active" : "",
                  on: {
                    click: function ($event) {
                      _vm.current = ix
                    },
                  },
                },
                [
                  _c("div", [_vm._v(_vm._s(s.nome))]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "icon icon-trash-o",
                    on: {
                      click: function ($event) {
                        return _vm.removeScansia(s)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "icon icon-pencil",
                    on: {
                      click: function ($event) {
                        return _vm.editScansia(s)
                      },
                    },
                  }),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-xs btn-primary",
                on: {
                  click: function ($event) {
                    return _vm.addScansia(_vm.progetto.scansie[0].righe, 20)
                  },
                },
              },
              [
                _c("i", { staticClass: "icon icon-plus" }),
                _vm._v(" Aggiungi modulo\n                "),
              ]
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "scansie-container" },
          _vm._l(_vm.progetto.scansie, function (s, ix) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: ix == _vm.current || 1 == 1,
                    expression: "ix==current || 1==1",
                  },
                ],
                key: s.id,
                staticClass: "scansia",
              },
              [
                _c("div", { staticClass: "row p-l-md" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-5 md-form-group md-label-static" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: s.nome,
                            expression: "s.nome",
                          },
                        ],
                        staticClass: "md-form-control",
                        attrs: { type: "text", required: "" },
                        domProps: { value: s.nome },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(s, "nome", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "md-control-label" }, [
                        _vm._v("Nome"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-2 md-form-group md-label-static" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: s.righe,
                            expression: "s.righe",
                          },
                        ],
                        staticClass: "md-form-control",
                        attrs: {
                          type: "number",
                          disabled: _vm.items() > 0,
                          required: "",
                        },
                        domProps: { value: s.righe },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(s, "righe", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "md-control-label" }, [
                        _vm._v("Righe"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-2 md-form-group md-label-static" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: s.colonne,
                            expression: "s.colonne",
                          },
                        ],
                        staticClass: "md-form-control",
                        attrs: {
                          type: "number",
                          disabled: _vm.items() > 0,
                          required: "",
                        },
                        domProps: { value: s.colonne },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(s, "colonne", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "md-control-label" }, [
                        _vm._v("Colonne"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-1 md-form-group md-label-static" },
                    [
                      _c("input", {
                        staticClass: "md-form-control touch-count",
                        attrs: { type: "number", disabled: "", required: "" },
                        domProps: { value: _vm.items() },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "md-control-label" }, [
                        _vm._v("Touch point"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "top-unit",
                    style: _vm.tustyle(ix),
                    on: {
                      click: function ($event) {
                        return _vm.selTUImage(ix)
                      },
                    },
                  },
                  [
                    s.top_unit != null
                      ? _c("div", { staticClass: "remove-top-unit" }, [
                          _c("i", {
                            staticClass: "icon icon-trash",
                            on: {
                              click: function ($event) {
                                return _vm.removeTopUnit(s, $event)
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    s.top_unit == null
                      ? _c("span", [
                          _vm._v(
                            "\n                    Top Unit\n                    "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("input", {
                      class: "tufile tufile_" + ix,
                      attrs: { type: "file", accept: "image/*" },
                      on: {
                        change: function ($event) {
                          return _vm.imageTUSelected($event, ix)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "table",
                  {
                    style: "width:" + _vm.cellwidth * s.colonne + "px",
                    attrs: { border: "0" },
                  },
                  _vm._l(+s.righe, function (r) {
                    return _c(
                      "tr",
                      { key: r, staticClass: "row-scansia" },
                      [
                        _c("div", {
                          staticClass: "row-click",
                          on: {
                            click: function ($event) {
                              return _vm.selectRow($event, s, r)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(+s.colonne, function (c) {
                          return _vm.is_visibile(ix, r - 1, c - 1)
                            ? _c(
                                "td",
                                {
                                  key: c,
                                  attrs: {
                                    colspan: _vm.colspan(
                                      ix,
                                      r - 1,
                                      c - 1,
                                      r.trasparente
                                    ),
                                    rowspan: _vm.rowspan(ix, r - 1, c - 1),
                                  },
                                  on: {
                                    drop: function ($event) {
                                      return _vm.drop($event, ix, r - 1, c - 1)
                                    },
                                    dragover: function ($event) {
                                      return _vm.dragover($event)
                                    },
                                    dragleave: function ($event) {
                                      return _vm.dragleave($event)
                                    },
                                  },
                                },
                                [
                                  _vm.ctxMenu ==
                                  s.id + "-" + (r - 1) + "x" + (c - 1)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ctx-menu",
                                          on: {
                                            click: function ($event) {
                                              return _vm.createFamiglia()
                                            },
                                          },
                                        },
                                        [_vm._v("Crea Famiglia")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  s.touchpoints.length > r - 1 &&
                                  s.touchpoints[r - 1].length > c - 1 &&
                                  s.touchpoints[r - 1][c - 1]
                                    ? _c("img", {
                                        class: s.touchpoints[r - 1][c - 1]
                                          .selected
                                          ? "selected"
                                          : "",
                                        style: _vm.imgStyle(s, ix, r, c),
                                        attrs: {
                                          draggable: "true",
                                          src: s.touchpoints[r - 1][c - 1]
                                            .immagine,
                                        },
                                        on: {
                                          dragstart: function ($event) {
                                            _vm.drag(
                                              $event,
                                              s.touchpoints[r - 1][c - 1],
                                              r - 1 + "x" + (c - 1),
                                              ix,
                                              r,
                                              c
                                            )
                                          },
                                          click: function ($event) {
                                            return _vm.select(
                                              $event,
                                              s,
                                              s.touchpoints[r - 1][c - 1],
                                              r - 1,
                                              c - 1
                                            )
                                          },
                                          contextmenu: function ($event) {
                                            return _vm.contextMenu(
                                              $event,
                                              s,
                                              r - 1,
                                              c - 1
                                            )
                                          },
                                        },
                                      })
                                    : _c("div", [_vm._v(" ")]),
                                  _vm._v(" "),
                                  s.layers.length > r - 1 &&
                                  s.layers[r - 1].length > c - 1 &&
                                  s.layers[r - 1][c - 1]
                                    ? _c("img", {
                                        staticClass: "layer",
                                        style:
                                          "width:" +
                                          ((_vm.cellwidth + 0) *
                                            _vm.colspan(
                                              ix,
                                              r - 1,
                                              c - 1,
                                              r.trasparente
                                            ) -
                                            1) +
                                          "px;height:" +
                                          ((_vm.cellwidth * _vm.cellmulti + 0) *
                                            _vm.rowspan(
                                              ix,
                                              r - 1,
                                              c - 1,
                                              true
                                            ) -
                                            1) +
                                          "px",
                                        attrs: {
                                          draggable: "true",
                                          src: s.layers[r - 1][c - 1].immagine,
                                        },
                                        on: {
                                          dragstart: function ($event) {
                                            _vm.drag(
                                              $event,
                                              s.layers[r - 1][c - 1],
                                              r - 1 + "x" + (c - 1),
                                              ix
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        _vm._l(+s.colonne, function (c) {
                          return s.bandelle.length > r - 1 &&
                            s.bandelle[r - 1].length > c - 1 &&
                            s.bandelle[r - 1][c - 1]
                            ? _c("img", {
                                key: "b_" + c,
                                staticClass: "bandella",
                                style:
                                  "left: " +
                                  _vm.cellwidth * (c - 1) +
                                  "px;width:" +
                                  ((_vm.cellwidth + 0) *
                                    _vm.colspan(ix, r - 1, c - 1, 2) -
                                    1) +
                                  "px;",
                                attrs: {
                                  draggable: "true",
                                  src: s.bandelle[r - 1][c - 1].immagine,
                                },
                                on: {
                                  dragstart: function ($event) {
                                    _vm.drag(
                                      $event,
                                      s.bandelle[r - 1][c - 1],
                                      r - 1 + "x" + (c - 1),
                                      ix
                                    )
                                  },
                                },
                              })
                            : _vm._e()
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade bs-modal-lg modal-scroll",
        attrs: {
          id: "dlgProgetto",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row agutter-lg" }, [
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 md-form-group md-label-static" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.progetto.nome,
                          expression: "progetto.nome",
                        },
                      ],
                      staticClass: "md-form-control",
                      attrs: { type: "text", required: "" },
                      domProps: { value: _vm.progetto.nome },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.progetto, "nome", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Nome"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-2 md-form-group md-label-static" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.progetto.num_scansie,
                          expression: "progetto.num_scansie",
                        },
                      ],
                      staticClass: "md-form-control",
                      attrs: { type: "number", required: "" },
                      domProps: { value: _vm.progetto.num_scansie },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.progetto,
                            "num_scansie",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Numero di moduli"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 md-form-group md-label-static" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.righe,
                          expression: "righe",
                        },
                      ],
                      staticClass: "md-form-control",
                      attrs: { type: "number", required: "" },
                      domProps: { value: _vm.righe },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.righe = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Numero di ripiani in ogni modulo"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row agutter-lg" },
                _vm._l(+_vm.progetto.num_scansie, function (ix) {
                  return _c(
                    "div",
                    {
                      key: ix,
                      staticClass:
                        "col-md-2 md-form-group md-label-static numero-colonne",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ripiani[ix - 1],
                            expression: "ripiani[ix-1]",
                          },
                        ],
                        staticClass: "md-form-control",
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.ripiani[ix - 1] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.ripiani, ix - 1, $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "md-control-label" }, [
                        _vm._v("Facing modulo " + _vm._s(ix)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.creaProgetto()
                    },
                  },
                },
                [_vm._v("Crea")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.chiudiModale()
                    },
                  },
                },
                [_vm._v("Annulla")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { position: "absolute", top: "-3000px" } },
      _vm._l(_vm.famiglie, function (f) {
        return _c(
          "div",
          { key: f.id, staticStyle: { display: "flex", width: "100%" } },
          _vm._l(f.touchpoints, function (t) {
            return _c("img", {
              key: t.id,
              class: f.id + "-" + t.id,
              style:
                "width: " +
                36 * t.touchpoint.colonne +
                "px;height:" +
                58 * t.touchpoint.righe +
                "px",
              attrs: { src: t.touchpoint.immagine },
            })
          }),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "btn-label" }, [
      _c("span", { staticClass: "icon icon-file-text-o icon-lg icon-fw" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "btn-label" }, [
      _c("span", { staticClass: "icon icon-file-pdf-o icon-lg icon-fw" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "btn-label" }, [
      _c("span", { staticClass: "icon icon-save icon-lg icon-fw" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("button", {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-hidden": "true",
        },
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Nuovo Progetto")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }