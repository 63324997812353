<template>
  <div>
      <topheader />
      <div class="layout-main">
            <!--<sidebar />-->
            <div class="layout-content">
                <div class="layout-content-body">
                    <router-view />            
                </div>
            </div>
            <div class="layout-footer">
                <div class="layout-footer-body">
                    &copy; BAT Planoweb 2023. All Rights Reserved.
                </div>
            </div>
            <!--
            <slot name="modals"></slot>
            
            
            -->
      </div>   
      <div class="wait" v-if="busy">
        <img src="/img/loading.gif">
    </div> 
  </div>
</template>

<script>

import sidebar from './layout/Sidebar.vue';
import topheader from './layout/Header.vue';
export default {
    data(){
        return {
            busy: false,
            user: ''
        }
    },
    components: {
        sidebar, topheader
    },
    mounted(){
        console.info('App mounted');
        fetch('/utenti/current').then(res => {
            if(res.ok){
                res.text().then(T => this.user = T);
            } 
        }).catch(err => {
            console.error(err);            
        })
    }
}
</script>

<style>

</style>