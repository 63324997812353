var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title-bar" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-warning btn-labeled pull-right",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.newProgetto()
              },
            },
          },
          [_vm._m(0), _vm._v("\n              Nuovo Progetto\n          ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-labeled pull-right",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.newCartella()
              },
            },
          },
          [_vm._m(1), _vm._v("\n              Nuova Cartella\n          ")]
        ),
        _vm._v(" \n      "),
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "title-bar-title" }, [
        _vm._v("\n          Progetti\n      "),
      ]),
      _vm._v(" "),
      _vm._m(2),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12" }, [
        _c("div", { staticClass: "panel" }, [
          _c("div", { staticClass: "panel-header" }, [
            _c(
              "ol",
              { staticClass: "breadcrumb" },
              [
                _c(
                  "li",
                  {
                    class: _vm.currentCartella == "" ? "active" : "",
                    on: {
                      drop: function ($event) {
                        return _vm.drop($event, { id: "", num_scansie: -1 })
                      },
                      dragover: function ($event) {
                        return _vm.dragover($event)
                      },
                      dragleave: function ($event) {
                        return _vm.dragleave($event)
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.openCartella("")
                          },
                        },
                      },
                      [_vm._v("Home")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.path, function (p) {
                  return _c(
                    "li",
                    {
                      key: p.id,
                      class: p.id == _vm.currentCartella ? "active" : "",
                      on: {
                        drop: function ($event) {
                          return _vm.drop($event, p)
                        },
                        dragover: function ($event) {
                          return _vm.dragover($event)
                        },
                        dragleave: function ($event) {
                          return _vm.dragleave($event)
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.openCartella(p.id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(p.nome))]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                {
                  staticClass: "table table-middle nowrap",
                  attrs: { id: "dt_progetti" },
                },
                [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { attrs: { id: "dtbody" } },
                    _vm._l(_vm.items, function (i) {
                      return _c("tr", { key: i.id }, [
                        _c(
                          "td",
                          {
                            staticClass: "name",
                            attrs: { draggable: true },
                            on: {
                              dragstart: function ($event) {
                                return _vm.drag($event, i, false)
                              },
                              drop: function ($event) {
                                return _vm.drop($event, i)
                              },
                              dragover: function ($event) {
                                return _vm.dragover($event)
                              },
                              dragleave: function ($event) {
                                return _vm.dragleave($event)
                              },
                            },
                          },
                          [
                            i.num_scansie != -1
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/progetti/dettaglio/" +
                                        i.id +
                                        "/" +
                                        (_vm.path.length == 1
                                          ? _vm.path[0].id
                                          : "root"),
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "icon icon-file" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(i.nome) +
                                        "\n                                  "
                                    ),
                                  ]
                                )
                              : _c("router-link", { attrs: { to: "#" } }, [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openCartella(i.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon icon-folder-o",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(i.nome) +
                                          "\n                                      "
                                      ),
                                    ]
                                  ),
                                ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(i.num_scansie > -1 ? i.num_scansie : "")
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.$formatDate(i.last_change))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(i.user_last_change))]),
                        _vm._v(" "),
                        i.num_scansie == -1
                          ? _c("td", [
                              _c("i", {
                                staticClass: "icon icon-folder-open-o",
                                on: {
                                  click: function ($event) {
                                    return _vm.openCartella(i.id)
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                                    \n                                  "
                              ),
                              i.nome != ".."
                                ? _c("i", {
                                    staticClass: "icon icon-pencil",
                                    on: {
                                      click: function ($event) {
                                        return _vm.renameCartella(i.id)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n                                    \n                                  "
                              ),
                              _c("i", {
                                staticClass: "icon icon-clone",
                                on: {
                                  click: function ($event) {
                                    return _vm.cloneCartella(i.id)
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                                    \n                                  "
                              ),
                              i.nome != ".."
                                ? _c("i", {
                                    staticClass: "icon icon-remove",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeCartella(i.id)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        i.num_scansie != -1
                          ? _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/progetti/dettaglio/" +
                                        i.id +
                                        "/" +
                                        (_vm.path.length == 1
                                          ? _vm.path[0].id
                                          : "root"),
                                    },
                                  },
                                  [_c("i", { staticClass: "icon icon-edit" })]
                                ),
                                _vm._v(
                                  "\n                                    \n                                  "
                                ),
                                _c("i", {
                                  staticClass: "icon icon-clone",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clone(i.id)
                                    },
                                  },
                                }),
                                _vm._v(
                                  "\n                                    \n                                  "
                                ),
                                _c("i", {
                                  staticClass: "icon icon-remove",
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(i.id)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "btn-label" }, [
      _c("span", { staticClass: "icon icon-plus icon-lg icon-fw" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "btn-label" }, [
      _c("span", { staticClass: "icon icon-plus icon-lg icon-fw" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "title-bar-description" }, [
      _c("small", [_vm._v("Gestione progetti")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Nome")]),
        _vm._v(" "),
        _c("th", { staticClass: "dt-no-sort" }, [_vm._v("Nr. moduli")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ultima modifica")]),
        _vm._v(" "),
        _c("th", [_vm._v("Utente")]),
        _vm._v(" "),
        _c("th", { staticClass: "dt-no-sort" }, [
          _c("i", { staticClass: "icon icon-cog" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }