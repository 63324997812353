<template>
<div>
  <canvas id="dragimg" style="position:absolute;top:-2000px;"></canvas>
  <div class="title-bar">
    <div>
        <div class="delete pull-left"  v-on:drop="removeItem($event)" v-on:dragover="dragover($event)">
            <!-- <i class="icon icon-2x icon-trash"></i> -->
            Trascina i touch point qui per eliminarli dal modulo
            <i class="pull-left icon icon-2x icon-trash"></i>
        </div>
        
        <button class="btn btn-primary btn-labeled pull-right" type="button" v-if="progetto.id!=null" @click="testo()">
            <span class="btn-label">
                <span class="icon icon-file-text-o icon-lg icon-fw"></span>
            </span>
            TESTO
        </button>
        <button class="btn btn-success btn-labeled pull-right m-r-sm" type="button" v-if="progetto.id!=null" @click="pdf()">
            <span class="btn-label">
                <span class="icon icon-file-pdf-o icon-lg icon-fw"></span>
            </span>
            PDF
        </button>
        <button class="btn btn-info btn-labeled pull-right m-r-sm" type="button" @click="save()" >
            <span class="btn-label">
                <span class="icon icon-save icon-lg icon-fw"></span>
            </span>
            Salva
        </button>
        <router-link class="btn btn-default btn-labeled pull-right m-r-sm" type="button" :to="'/progetti/'+path">
            <span class="btn-label">
                <span class="icon icon-close icon-lg icon-fw"></span>
            </span>
            Chiudi
        </router-link>
        <div class="m-r-sm" style="display:flex;justify-content:space-between;align-items:center;height:35px;width:135px;">
            {{sposta ? 'Modlit&agrave; sposta' : 'Modlit&agrave; duplica'}}
            <label class="switch switch-primary">
                <input class="switch-input" type="checkbox" v-model="sposta" checked="checked">
                <span class="switch-track"></span>
                <span class="switch-thumb"></span>
            </label>
        </div>
    </div>
    <h1 class="title-bar-title">
        <input class="md-form-control p-nome" type="text" v-model="progetto.nome" >
    </h1>
  </div>  
  <div class="progetto">
      <div class="palette" v-on:drop="removeItem($event)" v-on:dragover="dragover($event)">
          <div class="group" :key="g.id" v-for="g in palette">
              <div class="title collapsed">{{g.nome}}</div>
              <div class="body collapsed">
                <div class="group" :key="gg.id" v-for="gg in g.gruppi">
                    <div class="title collapsed">{{gg.nome}}</div>
                    <div class="body collapsed">
                        <div class="touchpoints-container" v-if="gg.touchpoints.length>0">
                            <div :key="t.id" v-for="t in gg.touchpoints" class="touchpoints" draggable="true" v-on:dragstart="drag($event, t, false)">
                                <img :src="t.immagine">
                                <div>{{t.nome}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="touchpoints-container" v-if="g.touchpoints.length>0">
                    <div :key="t.id" v-for="t in g.touchpoints" class="touchpoints" draggable="true" v-on:dragstart="drag($event, t, false)">
                        <img :src="t.immagine">
                        <div>{{t.nome}}</div>
                    </div>
                </div>
              </div>
          </div>
          <div class="group" v-if="famiglie.length>0">
              <div class="title collapsed">Famiglie</div>
              <div class="body collapsed">
                
                <div class="touchpoints-container">
                    <div :key="t.id" v-for="t in famiglie" class="touchpoints" draggable="true" v-on:dragstart="dragFamiglia($event, t)">
                        <img src="/img/famiglia.png">
                        <div>{{t.nome}}</div>
                        <div class="remove-famiglia">
                            <i class="icon icon-trash" @click="removeFamiglia(t, $event)"></i>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <div class="plano">
          <!-- <h3>Elenco Moduli</h3> -->
          <div class="scansia-tab-container">
            <div :key="s.id" v-for="(s,ix) in progetto.scansie" class="scansia-tab" :class="ix==current ? 'active' : ''" @click="current=ix">
                <div>{{s.nome}}</div>
                
                <i @click="removeScansia(s)" class="icon icon-trash-o"></i>
                <i @click="editScansia(s)" class="icon icon-pencil"></i>
            </div>
            <!-- <div class="scansia-tab"> -->
                <button class="btn btn-xs btn-primary" @click="addScansia(progetto.scansie[0].righe, 20)">
                    <i class="icon icon-plus"></i> Aggiungi modulo
                </button>
            <!-- </div> -->
          </div>
          <div class="scansie-container">
            <div :key="s.id" v-for="(s,ix) in progetto.scansie" class="scansia" v-show="ix==current || 1==1">
                <div class="row p-l-md">
                    <div class="col-md-5 md-form-group md-label-static">
                        <input class="md-form-control" type="text" v-model="s.nome"  required>
                        <label class="md-control-label">Nome</label>
                    </div>
                    <div class="col-md-1">&nbsp;</div>
                    <div class="col-md-2 md-form-group md-label-static">
                        <input class="md-form-control" type="number" :disabled="items() > 0" v-model="s.righe" required>
                        <label class="md-control-label">Righe</label>
                    </div>
                    <div class="col-md-1">&nbsp;</div>
                    <div class="col-md-2 md-form-group md-label-static">
                        <input class="md-form-control" type="number" :disabled="items() > 0" v-model="s.colonne" required>
                        <label class="md-control-label">Colonne</label>
                    </div>   
                    <div class="col-md-1 md-form-group md-label-static">
                        <input class="md-form-control touch-count" type="number" disabled :value="items()" required>
                        <label class="md-control-label">Touch point</label>
                    </div>
                </div>
                <div class="top-unit" @click="selTUImage(ix)" :style="tustyle(ix)">
                    <div v-if="s.top_unit!=null" class="remove-top-unit">
                        <i class="icon icon-trash" @click="removeTopUnit(s, $event)"></i>
                    </div>
                    <span v-if="s.top_unit==null">
                    Top Unit
                    </span>
                    <input type="file" accept="image/*" :class="'tufile tufile_'+ix" @change="imageTUSelected($event, ix)">
                </div>
                <table border="0" :style="'width:'+(cellwidth*s.colonne)+'px'">
                    <tr class="row-scansia" :key="r" v-for="r in +s.righe">
                        <div class="row-click" @click="selectRow($event, s, r)"></div>
                        <td :colspan="colspan(ix, r-1, c-1, r.trasparente)" :rowspan="rowspan(ix, r-1, c-1)" v-if="is_visibile(ix, r-1, c-1)" :key="c" v-for="c in +s.colonne" 
                            v-on:drop="drop($event, ix, r-1, c-1)" v-on:dragover="dragover($event)" v-on:dragleave="dragleave($event)">
                            <div v-if="ctxMenu==s.id+'-'+(r-1)+'x'+(c-1)"  @click="createFamiglia()" class="ctx-menu">Crea Famiglia</div>
                                
                            <img draggable="true" :style="imgStyle(s, ix, r, c)" :class="s.touchpoints[r-1][c-1].selected ? 'selected' : ''" v-on:dragstart="drag($event, s.touchpoints[r-1][c-1], (r-1)+'x'+(c-1), ix, r, c)" 
                                @click="select($event, s, s.touchpoints[r-1][c-1], r-1, c-1)"
                                @contextmenu="contextMenu($event, s, r-1, c-1)"
                                 v-if="s.touchpoints.length>r-1 && s.touchpoints[r-1].length>c-1 && s.touchpoints[r-1][c-1]" 
                                 :src="s.touchpoints[r-1][c-1].immagine">

                            <div v-else>&nbsp;</div>

                            <img draggable="true" class="layer" :style="'width:' + (((cellwidth+0)*colspan(ix, r-1, c-1, r.trasparente))-1) + 'px;height:'+(((cellwidth*cellmulti+0)*rowspan(ix, r-1, c-1, true))-1)+'px'" v-on:dragstart="drag($event, s.layers[r-1][c-1], (r-1)+'x'+(c-1), ix)" 
                                 v-if="s.layers.length>r-1 && s.layers[r-1].length>c-1 && s.layers[r-1][c-1]" 
                                 :src="s.layers[r-1][c-1].immagine">

                        </td>
                        <img v-for="c in +s.colonne" :key="'b_'+c" draggable="true" class="bandella" :style="'left: '+(cellwidth*(c-1))+'px;width:' + (((cellwidth+0)*colspan(ix, r-1, c-1, 2))-1) + 'px;'" v-on:dragstart="drag($event, s.bandelle[r-1][c-1], (r-1)+'x'+(c-1), ix)" 
                                 v-if="s.bandelle.length>r-1 && s.bandelle[r-1].length>c-1 && s.bandelle[r-1][c-1]" 
                                 :src="s.bandelle[r-1][c-1].immagine">
                    </tr>
                </table>
            </div>
          </div>
      </div>
  </div>


  <div class="modal fade bs-modal-lg modal-scroll" id="dlgProgetto" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title">Nuovo Progetto</h4>
                </div>
                
                <div class="modal-body">
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <input class="md-form-control" type="text" v-model="progetto.nome" required>
                            <label class="md-control-label">Nome</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2 md-form-group md-label-static">
                            <input class="md-form-control" type="number" v-model="progetto.num_scansie" required>
                            <label class="md-control-label">Numero di moduli</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <input class="md-form-control" type="number" v-model="righe" required>
                            <label class="md-control-label">Numero di ripiani in ogni modulo</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                    <div class="row agutter-lg">
                        <div class="col-md-2 md-form-group md-label-static numero-colonne" v-for="ix in +progetto.num_scansie" :key="ix">
                            <input class="md-form-control" type="text" v-model="ripiani[ix-1]" required>
                            <label class="md-control-label">Facing modulo {{ix}}</label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" @click="creaProgetto()">Crea</button>
                    <button type="button" class="btn btn-default" @click="chiudiModale()">Annulla</button>
                </div>
            </div>
        </div>
    </div>     
    <div style="position: absolute; top: -3000px">
        <div style="display: flex; width: 100%;" :key="f.id" v-for="f in famiglie">
            <img :key="t.id" :style="'width: '+(36*t.touchpoint.colonne)+'px;height:'+(58*t.touchpoint.righe)+'px'" :src="t.touchpoint.immagine" :class="f.id+'-'+t.id" v-for="t in f.touchpoints" />
        </div>
    </div>
</div>
</template>

<script>
import html2cavnas from 'html2canvas';
import { compileToFunctions } from 'vue-template-compiler';

export default {
    data(){
        return {
            sposta: true,
            nonchiedere: true,
            selection: [],
            rowSelection: false,
            cellwidth: 40,
            cellmulti: 1.575,
            current: 0,
            famiglie: [],
            palette: [],
            ripiani: [],
            righe: 10,
            path: '',
            ctxMenu: '',
            progetto: {
                id: null,
                nome: 'Nuovo progetto',
                num_scansie: 0,
                scansie: []
            }
        }
    },
    beforeRouteLeave (to, from, next) {
        if(this.nonchiedere){
           next(true);
           return; 
        }
        Swal.fire({
            title: 'Vuoi salvare il progetto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No, grazie'
        }).then((result) => {
            if(result.value) {
                this.save0(false);
                next(true);
            } else {
                next(true);
            }
        });
    },
    watch: {
        "progetto.nome"(val){
            this.nonchiedere = false;
        },
        "progetto.num_scansie"(val){
            this.nonchiedere = false;
            this.$nextTick(() => this.$initElephant());
        }
    },
    methods: {
        removeFamiglia(f, e){
            event.preventDefault();
            event.stopPropagation();
            Swal.fire({
                title: 'Vuoi eliminare la famiglia '+f.nome+'?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie'
            }).then((result) => {
                if (result.value) {
                    fetch('/progetti/famiglia', {
                        method: 'DELETE',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({id: f.id})
                    }).then(res => {
                        this.$parent.busy = false;
                        if(res.ok){
                            toastr.success('Eliminazione effettuata');
                            res.json().then(r => { 
                                this.famiglie = r;
                                this.famiglie.sort((A,B) => A.nome.localeCompare(B.nome));
                                for(let i=0;i<this.famiglie.length;i++){
                                    this.famiglie[i].touchpoints.sort((a,b) => a.colonna-b.colonna);
                                }
                            });
                        } else {
                            toastr.error(res.statusText, 'Errore');
                        }
                    }).catch(err => {
                        this.$parent.busy = false;
                        toastr.error(err, 'Errore');
                    });
                }
            });
        },
        createFamiglia(){
            this.ctxMenu = '';

            Swal.fire({
                title: 'Vuoi creare una famiglia con gli elementi selezionati?',
                icon: 'question',
                text: 'Inserire il nome della famiglia',
                input: 'text',
                inputAttributes: {
                    required: true
                },
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie'
            }).then((result) => {
                if (result.dismiss!='cancel' && result.value!='') {
                    this.$parent.busy = true;
                    fetch('/progetti/creaFamiglia', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({nome: result.value, elementi: this.selection})
                    }).then(res => {
                        this.$parent.busy = false;
                        if(res.ok){
                            toastr.success('Creazione effettuata');
                            for(let kk=0;kk<this.selection.length;kk++){
                                this.selection[kk].selected = false;
                            }
                            this.selection = [];
                            res.json().then(r => { 
                                this.famiglie = r;
                                this.famiglie.sort((A,B) => A.nome.localeCompare(B.nome));
                                for(let i=0;i<this.famiglie.length;i++){
                                    this.famiglie[i].touchpoints.sort((a,b) => a.colonna-b.colonna);
                                }
                            });
                        } else {
                            if(res.status==400){
                                res.json().then(j => toastr.error(j.detail, 'Errore'));
                            } else {
                                console.error(res);
                                toastr.error(res.statusText, 'Errore');
                            }
                        }
                    }).catch(err => {
                        this.$parent.busy = false;
                        toastr.error(err, 'Errore');
                    });
                }
            });

            
        },
        contextMenu(e, scansia, r, c){
            e.preventDefault();
            e.stopPropagation();
            if(this.selection.length>1 && this.rowSelection==false){
                this.ctxMenu = scansia.id+'-'+r+'x'+c;
            }
        },
        select(e, scansia, tp, riga, colonna){
            if(window.event.ctrlKey){ 
                if(this.rowSelection){
                    for(let k=0;k<this.selection.length;k++){
                        this.selection[k].selected = false;
                    }
                    this.selection = [];
                } 
                this.rowSelection = false;
                tp.scansia = scansia.id;
                tp.riga = riga;
                tp.colonna = colonna;
                tp.domImg = e.currentTarget;
                if(tp.selected){
                    tp.selected = false;
                    this.selection.splice(this.selection.indexOf(tp));
                } else {
                    if(this.selection.length==this.selection.filter(A => A.scansia==scansia.id).length){
                        tp.selected = true;
                        this.selection.push(tp);
                    } else {
                        toastr.warning('Non è possibile selezionare elementi di scansie differenti');
                    }
                }
                
                this.$forceUpdate();
            }
        },
        selectRow(e, scansia, riga){
            for(let k=0;k<this.selection.length;k++){
                if(this.selection[k].riga!=riga-1){
                    this.selection[k].selected = false;
                }
            }
            
            const row = scansia.touchpoints[riga-1];
            for(let c=0;c<row.length;c++){
                if(row[c]){
                    this.rowSelection = true;
                    const sel = !row[c].selected;
                    row[c].scansia = scansia.id;
                    row[c].riga = riga-1;
                    row[c].colonna = c;
                    row[c].domImg = e.currentTarget.parentNode.children[c+1].children[0];
                    row[c].selected = sel;
                    if(sel){
                        this.selection.push(row[c]);
                    } else {
                        const ixSplice = this.selection.indexOf(row[c]);
                        if(ixSplice>=0){
                            this.selection.splice(ixSplice);
                        }
                    }
                }
            }
            
            this.$forceUpdate();
        },
        items(ix){
            let count = 0;
            const s = this.progetto.scansie[ix];
            for(let i=0;s && s.touchpoints && i<s.touchpoints.length;i++){
                for(let j=0;j<s.touchpoints[i].length;j++){
                    if(s.touchpoints[i][j]) {
                        count++;
                    }
                }
            }
            return count;
        },
        is_visibile(ix, r, c){
            const scansia = this.progetto.scansie[ix];
            for(let i=c-1;i>=0;i--){
                if(scansia.touchpoints[r][i] && i + scansia.touchpoints[r][i].colonne > c){
                    return false;
                }
            }
            for(let k=r-1;k>=0;k--){
                for(let i=c;i>=0;i--){
                    if(scansia.touchpoints[k][i] && k+scansia.touchpoints[k][i].righe > r && i + scansia.touchpoints[k][i].colonne > c){
                        return false;
                    }
                }
            }
            return true;
        },
        imgStyle(s, ix, r, c){
            const rowspan = this.rowspan(ix, r-1, c-1);
            const w = (((this.cellwidth+1)*this.colspan(ix, r-1, c-1, 0))-1);
            const h = (((this.cellwidth*this.cellmulti+0)*rowspan)-1) + ((rowspan-1)*21);
            // const img = s.touchpoints[r-1][c-1].immagine;
            // const image = document.createElement('img');
            // image.src = img;
            // const iw = image.width;
            // const ih = image.height;

            // let dw = h * iw / ih;
            // let dh = h;
            // if(dw>w){
            //     dw = w;
            //     dh = w * ih / iw;
            // }

            const style = 'width:' + (w-4) + 'px;height:' + (h-4) +'px';
            // console.info(style);
            return style;
        },
        rowspan(ix, r, c, layer){
            let obj = (layer ? 'layers' : 'touchpoints');
            const s = this.progetto.scansie[ix];
            if(s[obj].length<=r || s[obj][r].length<=c){
                return 1;
            }
            const tp = s[obj][r][c];
            if(tp){
                return tp.righe;
            } else {
                return 1;
            }
        },
        colspan(ix, r, c, layer){
            let obj = (layer ? 'layers' : 'touchpoints');
            if(layer==2){
                obj = 'bandelle';
            }
            const s = this.progetto.scansie[ix];
            if(s[obj].length<=r || s[obj][r].length<=c){
                return 1;
            }
            
            const tp = s[obj][r][c];
            if(tp){
                
                // if(tp.trasparente){
                //     return tp.colonne;;
                // } else {
                //     return tp.colonne;
                // }
                if(obj=='bandelle'){
                    console.info(tp);
                }
                return tp.colonne;
            } else {
                return 1;
            }
        },
        // totColspan(ix, r){
        //     const s = this.progetto.scansie[ix];
        //     if(s.touchpoints.length<=r){
        //         return 0;
        //     }
        //     let res = 0;
        //     for(let i=0;i<s.touchpoints[r].length;i++){
        //         if(s.touchpoints[r][i]){
        //             res += (s.touchpoints[r][i].colonne - 1);
        //         }
        //     }
        //     return res;
        // },
        tustyle(ix){
            const tu = this.progetto.scansie[ix].top_unit;
            if(tu==null || tu==''){
                return '';
            } else {
                return "background-image: url('"+tu+"');background-size: cover; background-repeat: no-repeat;background-position: center center;";
            }
        },
        selTUImage(ix){
            $('.tufile_'+ix).click();
        },
        imageTUSelected(e, ix){
            const file = e.target;
            this.nonchiedere = false;
            if(file.files && file.files.length>0){
                const reader = new FileReader();
                reader.onload = () => {
                    const imgData = reader.result;
                    try {
                        this.progetto.scansie[ix].top_unit = imgData;
                        this.$forceUpdate();
                    } catch(ex){}
                };
                reader.readAsDataURL(file.files[0]);
            }
            file.value = '';
        },
        removeTopUnit(s, event){
            event.preventDefault();
            event.stopPropagation();
            Swal.fire({
                title: 'Vuoi eliminare la top unit?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie'
            }).then((result) => {
                if (result.value) {
                    s.top_unit = null;
                }
            });
        },
        removeSelection(){
            if(this.selection.length==0) return;
            Swal.fire({
                title: 'Vuoi eliminare i touch point selezionati?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie'
            }).then((result) => {
                if (result.value) {
                    this.nonchiedere = false;
                    for(let ix=0;ix<this.selection.length;ix++){
                        const tp = this.selection[ix];
                        const i = tp.riga;
                        const j = tp.colonna;
                        const sx = this.progetto.scansie.findIndex(s => s.id==tp.scansia);
                        if(tp.trasparente){
                            const layers = this.progetto.scansie[sx].layers;
                            layers[i][j] = null;
                        } else {
                            const touchpoints = this.progetto.scansie[sx].touchpoints;
                            touchpoints[i][j] = null;
                        }
                    }
                    this.selection = [];
                    this.$forceUpdate();
                }
            });
        },
        removeItem(ev){
            ev.preventDefault();
            const data = JSON.parse(ev.dataTransfer.getData("data"));
            const ix = JSON.parse(ev.dataTransfer.getData("ix"));
            let replace = ev.dataTransfer.getData("replace");
            if(this.selection.length>0){
                this.removeSelection();
            } else {
                Swal.fire({
                    title: 'Vuoi eliminare il touch point?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No, grazie'
                }).then((result) => {
                    if (result.value) {
                        this.nonchiedere = false;
                        replace = replace.split('x');
                        const i = replace[0];
                        const j = replace[1];
                        if(data.trasparente){
                            const layers = this.progetto.scansie[ix].layers;
                            layers[i][j] = null;
                        } else {
                            const touchpoints = this.progetto.scansie[ix].touchpoints;
                            touchpoints[i][j] = null;
                        }
                        this.$forceUpdate();
                    }
                });
            }
        },
        editScansia(s){
            Swal.fire({
                title: 'Numero di facing',
                icon: 'question',
                input: 'number',
                inputAttributes: {
                    value: s.colonne
                },
                showCancelButton: true,
                confirmButtonText: 'Modifica',
                cancelButtonText: 'Annulla'
            }).then((result) => {
                if (result.value) {
                    const colonne = result.value;
                    
                    if(colonne<s.colonne){
                        const deleteCount = s.colonne - colonne;
                        Swal.fire({
                            title: 'Attenzione, questa operazione cancellera le ultime ' + deleteCount+ ' colonne anche se contengono degli elementi, vuoi continuare?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No, grazie'
                        }).then((result) => {
                            if (result.value) {
                                
                                for(let ix=0;ix<s.righe;ix++){
                                    s.touchpoints[ix].splice(s.touchpoints[ix].length-deleteCount, deleteCount);
                                    s.layers[ix].splice(s.layers[ix].length-deleteCount, deleteCount);
                                    s.bandelle[ix].splice(s.bandelle[ix].length-deleteCount, deleteCount)
                                }
                                s.colonne = +colonne;
                                this.nonchiedere = false;
                                this.$forceUpdate();
                            }
                        });
                    } else {
                        Swal.fire({
                            title: 'Confermi il nuovo numero di facing ('+colonne+')?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No, grazie'
                        }).then((result) => {
                            if (result.value) {
                                const addCount = colonne-s.colonne;
                                for(let ix=0;ix<s.righe;ix++){
                                    for(let i=0;i<addCount;i++){
                                        s.touchpoints[ix].push('');
                                        s.layers[ix].push('');
                                        s.bandelle[ix].push('');
                                    }
                                }
                                s.colonne = +colonne;
                                this.nonchiedere = false;
                                this.$forceUpdate();
                            }
                        });
                    }
                    
                    
                }
            });
            setTimeout(() => {
                $('.swal2-input').val(s.colonne);
            }, 400);
        },
        removeScansia(s){
            Swal.fire({
                title: 'Vuoi eliminare il modulo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie'
            }).then((result) => {
                if (result.value) {
                    this.nonchiedere = false;
                    const ix = this.progetto.scansie.findIndex(S => S==s);
                    this.progetto.scansie.splice(ix, 1);
                }
            });
        },
        addScansia(righe, colonne){
            Swal.fire({
                title: 'Numero di facing',
                icon: 'question',
                input: 'number',
                showCancelButton: true,
                confirmButtonText: 'Aggiungi',
                cancelButtonText: 'Annulla'
            }).then((result) => {
                if (result.value) {
                    colonne = +result.value;
                    const ss = {
                        nome: 'Modulo #' + (this.progetto.scansie.length + 1),
                        righe: righe,
                        colonne: colonne,
                        top_unit: null
                    }
                    ss.touchpoints = Array(ss.righe);
                    ss.layers = Array(ss.righe);
                    ss.bandelle = Array(ss.righe);
                    for(let ix=0;ix<ss.righe;ix++){
                        ss.touchpoints[ix] = Array(ss.colonne);
                        ss.layers[ix] = Array(ss.colonne);
                        ss.bandelle[ix] = Array(ss.colonne);
                    }
                    this.progetto.scansie.push(ss);
                    this.nonchiedere = false;
                }
            });
            
        },
        dragover(ev) {
           ev.preventDefault();
           $(ev.target).addClass('dragging');
        },
        dragleave(ev){
            $(ev.target).removeClass('dragging');
        },
        drag(ev, t, replace, ix, r, c) {
            ev.dataTransfer.setData("data", JSON.stringify(t));
            ev.dataTransfer.setData("replace", replace);
            ev.dataTransfer.setData("ix", ix);
            ev.dataTransfer.setData("r", r-1);
            ev.dataTransfer.setData("c", c-1);
            if(this.selection.length>0 && this.selection.indexOf(t)>-1){
                let min = {x: 999999, y: 999999};
                let max = {x: 0, y: 0};
                for(let ii=0;ii<this.selection.length;ii++){
                    let sel = this.selection[ii];
                    let rect = sel.domImg.getBoundingClientRect();
                    min.x = Math.min(min.x, rect.x);
                    min.y = Math.min(min.y, rect.y);
                    max.x = Math.max(max.x, rect.x+rect.width);
                    max.y = Math.max(max.y, rect.y+rect.height);
                }
                let canvas = document.getElementById('dragimg');
                canvas.width = max.x - min.x;
                canvas.height = max.y - min.y;
                let ctx = canvas.getContext('2d');
                for(let ii=0;ii<this.selection.length;ii++){
                    let sel = this.selection[ii];
                    let rect = sel.domImg.getBoundingClientRect();
                    ctx.drawImage(sel.domImg, 0, 0, sel.domImg.naturalWidth, sel.domImg.naturalHeight, rect.x - min.x, rect.y - min.y, rect.width, rect.height);
                }
                
                ev.dataTransfer.setDragImage(canvas, 10, 10);
            }
        },
        dragFamiglia(ev, t) {
            ev.dataTransfer.setData("data", JSON.stringify(t.touchpoints[0].touchpoint));
            ev.dataTransfer.setData("famiglia", true);
            ev.dataTransfer.setData("replace", false);
            ev.dataTransfer.setData("ix", undefined);
            ev.dataTransfer.setData("r", undefined);
            ev.dataTransfer.setData("c", undefined);
            const data = []
            let min = {x: 999999, y: 999999};
            let max = {x: 0, y: 0};
            for(let ii=0;ii<t.touchpoints.length;ii++){
                t.touchpoints[ii].domImg = document.getElementsByClassName(t.id+'-'+t.touchpoints[ii].id)[0];
                let sel = t.touchpoints[ii];
                data.push({...sel.touchpoint, riga: 0, colonna: ii});
                let rect = sel.domImg.getBoundingClientRect();
                console.info(rect);
                min.x = Math.min(min.x, rect.x);
                min.y = Math.min(min.y, rect.y+3000);
                max.x = Math.max(max.x, rect.x+rect.width);
                max.y = Math.max(max.y, rect.y+3000+rect.height);
            }
            this.selection = data;
            let canvas = document.getElementById('dragimg');
            canvas.width = max.x - min.x;
            canvas.height = max.y - min.y;
            let ctx = canvas.getContext('2d');
            for(let ii=0;ii<t.touchpoints.length;ii++){
                let sel = t.touchpoints[ii];
                let rect = sel.domImg.getBoundingClientRect();
                ctx.drawImage(sel.domImg, 0, 0, sel.domImg.naturalWidth, sel.domImg.naturalHeight, rect.x - min.x, rect.y+3000 - min.y, rect.width, rect.height);
            }
            
            ev.dataTransfer.setDragImage(canvas, 10, 10);
            
        },
        calcolaSpazioOccupato(item, r, c){
            const spaziooccupato = [];
            for(let i=0;i<item.righe;i++){
                for(let j=0;j<item.colonne;j++){
                    spaziooccupato.push((r+i)+'x'+(c+j))
                }
            }
            return spaziooccupato;
        },
        siAccavalla(scansia, item, r, c, spazioorig) {
            if(item.trasparente) return false;
            const spaziooccupato = this.calcolaSpazioOccupato(item, r, c);
            for(let i=0;i<scansia.touchpoints.length;i++){
                for(let j=0;j<scansia.touchpoints[i].length;j++){
                    const altro = scansia.touchpoints[i][j];
                    if(altro){
                        const spazioAltro = this.calcolaSpazioOccupato(altro, i, j);
                        const same = spazioorig.filter(value => spazioAltro.includes(value)).length == spazioorig.length && 
                                        spazioorig.length==spazioAltro.length;
                        if(!same && spaziooccupato.filter(value => spazioAltro.includes(value)).length>0){
                            return altro;
                        }
                    }
                }
            }
            return false;
        },
        // movedropped(){

        // },
        drop(ev, ix, r, c) {
            ev.preventDefault();
            const data = JSON.parse(ev.dataTransfer.getData("data"));
            let origR = +ev.dataTransfer.getData("r");
            let origC = +ev.dataTransfer.getData("c");
            const scansiaOrigIx = +ev.dataTransfer.getData("ix");
            const scansia = this.progetto.scansie[ix];
            const scansiaOrig = this.progetto.scansie[scansiaOrigIx];
            let replace = ev.dataTransfer.getData("replace");
            let famiglia = ev.dataTransfer.getData("famiglia");
            $(ev.target).removeClass('dragging');
            if(isNaN(origC)) origC = 0;
            if(isNaN(origR)) origR = 0;
            const offsetY = r - origR;
            const offsetX = c - origC; 
            const selection = [];
            console.info(data);
            console.info($(ev.target).hasClass('selected'));
            for(let ii=0; ii<this.selection.length;ii++){
                selection.push(this.selection[ii]);
            }
            if(selection.length==0){
                data.colonna = origC;
                data.riga = origR;
                selection.push(data);
            }
            //controllare se data.colonne+c è uguale a item.colonne+item.colonna+offsetX !!! dovrebbe esserlo
            /*
            console.info({data: data, c: c, r: r, offsetX: offsetX, offsetY: offsetY});
            if(data.colonne+c>scansia.colonne || data.righe+r>scansia.righe){
                return;
            }

            const spazioOrig = this.calcolaSpazioOccupato(data, origR, origC);
            if(this.siAccavalla(scansia, data, r, c, spazioOrig)){
                return;
            }
            */
            for(let ii=0; ii<selection.length;ii++){
                const item = selection[ii];
                if(
                    item.colonna+offsetX<0 || item.colonne+item.colonna+offsetX>scansia.colonne || 
                    item.riga+offsetY<0 || item.righe+item.riga+offsetY>scansia.righe
                ){
                    console.info({mess:'non ci va', item: item, offsetX: offsetX, offsetY: offsetY, scansia: scansia});
                    return;
                }
                const spazioOrig = this.calcolaSpazioOccupato(item, item.riga, item.colonna);
                if(this.siAccavalla(scansia, item, item.riga+offsetY, item.colonna+offsetX, spazioOrig) && item.trasparente!=2){
                    console.info('siAccavalla');
                    return;
                }
            }
            // console.info('abbbaaa' + this.selection.length);
            this.nonchiedere = false;
            
            for(let ii=0; ii<selection.length;ii++){
                const item = selection[ii];
                console.info(item);
                //const item = data;
                if(item.trasparente==1){
                    scansia.layers[r][c] = item;
                    if(replace && replace!='false' && this.sposta){
                        replace = replace.split('x');
                        const i = replace[0];
                        const j = replace[1];
                        if(i!=r || j!=c){
                            scansiaOrig.layers[i][j] = null;
                        }
                    }
                } else if(item.trasparente==2){
                    scansia.bandelle[r][c] = item;
                    if(replace && replace!='false' && this.sposta){
                        replace = replace.split('x');
                        const i = replace[0];
                        const j = replace[1];
                        if(i!=r || j!=c){
                            scansiaOrig.bandelle[i][j] = null;
                        }
                    }
                } else {
                    const touchpoints = scansia.touchpoints;
                    r = item.riga + offsetY;
                    c = item.colonna + offsetX;
                    console.info({tp: touchpoints, r:r, c:c});
                    if(!touchpoints[r][c]){
                        if(replace && replace!='false' && this.sposta){
                            //replace = replace.split('x');
                            //const i = replace[0];
                            //const j = replace[1];
                            const i = item.riga;
                            const j = item.colonna;
                            scansiaOrig.touchpoints[i][j] = null;
                        }
                        item.selected = false;
                        touchpoints[r][c] = item;
                        
                    }
                }
            }
            this.selection = [];
            this.$forceUpdate();            
        },
        save0(navigate){
            console.info('navigate: ' + navigate);
            this.$parent.busy = true;
            this.nonchiedere = true;
            navigate = false;
            fetch('/progetti/save', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(this.progetto)
            }).then(res => {
                this.$parent.busy = false;
                if(res.ok){
                    toastr.success('Salvataggio effettuato');
                    res.text().then(t => {
                        this.progetto.id = t;
                    });
                    if(navigate){
                        console.info('navigo');
                        this.nonchiedere = true;
                        this.$router.go(-1);
                    }
                } else {
                    toastr.error(res.statusText, 'Errore');
                }
            }).catch(err => {
                this.$parent.busy = false;
                toastr.error(err, 'Errore');
            });
        },
        save(){
            this.save0(true);
            // Swal.fire({
            //     title: 'Vuoi salvare il progetto?',
            //     icon: 'question',
            //     showCancelButton: true,
            //     confirmButtonText: 'Si',
            //     cancelButtonText: 'No, grazie',
            // }).then((result) => {
            //     if (result.value) {
            //         this.save0(true);
            //     }
            // });            
        },
        creaProgetto(){
            let ok = true;
            if(!this.progetto.nome || this.progetto.nome=='') ok = false;
            if(!this.ripiani || this.ripiani.length<=0) ok = false;
            
            for(let i=0;i<this.ripiani.length;i++){
                if(!+this.ripiani[i]>0){
                    ok = false;
                }
            }
            if(ok){
                for(let i=0;i<this.ripiani.length;i++){
                    this.addScansia(+this.righe, +this.ripiani[i]);
                }
                this.$hideModal();
            } else {
                toastr.error('Valorizzare correttamente tutti i campi');
            }
        },
        chiudiModale(){
            this.progetto.nome = 'Nuovo progetto';
            this.progetto.num_scansie = 0;
            this.righe = 10;
            this.$nextTick(() => this.$hideModal());
            this.$router.go(-1);
        },
        pdf(){
            let w = 0;
            $('.scansia').each(function(){ w += $(this).width()+10+42; });
            for(let i=0;i<this.progetto.scansie.length;i++){
                w += this.progetto.scansie[i].colonne;
            }
            $('.plano').css('width', w+'px');            
            $('.palette').css('width', '0');
            let config;
            if($('.scansia').length>1){
                config = {
                    windowWidth: w,
                    windowHeight: $('.scansie-container').height(),
                    width: w,
                    height: $('.scansie-container').height(),
                    scale: 5
                };
            } else {
                config = {  scale: 5 };
            }
            html2cavnas(document.querySelector('.scansie-container'), config).then(canvas => {
                console.log("pdf");
                const img = canvas.toDataURL('image/png', 1);
                $('.plano').css('width', '80%');
                $('.palette').css('width', '20%');
                this.$parent.busy = true;
                fetch('/progetti/pdf', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        img: img,
                        title: this.progetto.nome
                    })
                }).then(res => {
                    this.$parent.busy = false;
                    if(res.ok){
                        // console.info(res);
                        res.blob().then(r => {
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            // console.info(a);
                            const url = window.URL.createObjectURL(r);
                            a.href = url;
                            a.download = this.progetto.nome+'.pdf';
                            a.click();
                            window.URL.revokeObjectURL(url);
                        })
                    } else {
                        toastr.error(res.statusText, 'Errore');
                    }
                }).catch(err => {
                    this.$parent.busy = false;
                    toastr.error(err, 'Errore');
                });
                
            })
        },
        testo(){
            let txt = '';
            for(let ix = 0; ix<this.progetto.scansie.length; ix++){
                const scansia = this.progetto.scansie[ix];
                txt += scansia.nome + "\n";
                for(let r = 0; r<scansia.righe; r++){
                    txt += 'Riga ' + (r+1) + "\n";
                    for(let c = 0; c<scansia.colonne; c++){
                        const tp = scansia.touchpoints[r][c];
                        if(tp){
                            txt += tp.nome + "\t\t";
                        }
                    }
                    txt += "\n";
                }
                txt += "\n\n";
            }
            
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt));
            element.setAttribute('download', 'export.txt');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    },
    mounted(){
        this.progetto.id = this.$route.params.id;
        this.path = this.$route.params.path;
        if(this.path=='root') this.path = '';
        fetch('/gruppi/palette').then(res => {
            res.json().then(r => { 
                this.palette = r;
                this.palette.sort((A,B) => A.nome.localeCompare(B.nome));
                for(let kk=0;kk<this.palette.length;kk++){
                    this.palette[kk].gruppi.sort((A,B) => A.nome.localeCompare(B.nome));
                }
                this.$nextTick(() => {
                    this.nonchiedere = true;
                    $('.title').off('click').on('click', function() {
                        if(!$(this).hasClass('collapsed')){
                            $(this).addClass('collapsed');
                            $(this).next().addClass('collapsed');
                        } else {
                            $(this).removeClass('collapsed');
                            $(this).next().removeClass('collapsed');
                        }
                    });
                });
            });
        });

        fetch('/progetti/famiglie').then(res => {
            res.json().then(r => { 
                this.famiglie = r;
                this.famiglie.sort((A,B) => A.nome.localeCompare(B.nome));
                for(let i=0;i<this.famiglie.length;i++){
                    this.famiglie[i].touchpoints.sort((a,b) => a.colonna-b.colonna);
                }
                this.$nextTick(() => {
                    $('.title').off('click').on('click', function() {
                        if(!$(this).hasClass('collapsed')){
                            $(this).addClass('collapsed');
                            $(this).next().addClass('collapsed');
                        } else {
                            $(this).removeClass('collapsed');
                            $(this).next().removeClass('collapsed');
                        }
                    });
                });
            });
        });


        if(this.progetto.id!=null && this.progetto.id!=0){
            fetch('/progetti/load/' + this.progetto.id).then(res => {
                res.json().then(r => {
                    
                    for(let i=0;i<r.scansie.length;i++){
                        const s = r.scansie[i];
                        const touchpoints = s.touchpoints;
                        s.touchpoints = Array(s.righe);
                        s.layers = Array(s.righe);
                        s.bandelle = Array(s.righe);
                        for(let j=0;j<s.righe;j++){
                            s.touchpoints[j] = Array(s.colonne);
                            s.layers[j] = Array(s.colonne);
                            s.bandelle[j] = Array(s.colonne);
                            for(let z=0;z<s.colonne;z++){
                                const tts = touchpoints.filter(T => T.riga==j && T.colonna==z);
                                for(let k=0;k<tts.length;k++){
                                    const tt = tts[k];
                                    if(tt.touchpoint.trasparente==1){
                                        s.layers[j][z] = tt.touchpoint;
                                    } else if(tt.touchpoint.trasparente==2){
                                        s.bandelle[j][z] = tt.touchpoint;
                                    } else {
                                        s.touchpoints[j][z] = tt.touchpoint;
                                    }
                                }
                            }
                        }
                    }
                    r.scansie.sort((A,B) => A.nome.localeCompare(B.nome));
                    this.progetto = r;
                    this.nonchiedere = true;
                });
            })
        } else {
            this.$nextTick(() => {
                this.progetto.nome = 'Nuovo progetto';
                this.progetto.num_scansie = 0;
                this.progetto.cartelle_id = this.path;
                this.righe = 10;
                this.$showModal('#dlgProgetto');
            });
        }
        this.$nextTick(() => {
            this.$initElephant();
            $('.palette').css('height', ($(window).height()-146)+'px');
            $(window).scroll(function(e){
                $('.palette').css('margin-top', jQuery(window).scrollTop()+'px');
            });
        });
        this.$initApp();
        this.$initElephant();
        console.info('ProgettoEdit mounted');
        window.addEventListener("keydown", e => {
            if(e.key=='Control'){
                document.body.style.cursor = 'copy';
            }
        });
        window.addEventListener("keyup", e => {
            console.info(e.key);
            if(e.key=='Control'){
                document.body.style.cursor = 'default';
            } else if(e.key=='Escape'){
                this.ctxMenu = '';
            } else if(e.key=='Delete'){
                
            }
        });
        document.body.addEventListener('click', e => {
            this.ctxMenu = '';
        });
    }
}
</script>

<style scoped>
.md-form-group {
    margin-bottom: -5px;
    margin-top: 10px;
    /*
    padding-bottom: 5px; */
}
.numero-colonne {
    padding-left: 10px;
    padding-right: 10px;
} 
.numero-colonne:first-child,.numero-colonne:nth-child(6) {
    margin-left: 75px;
}
/* .scansia-remove {
    float: right;
    border: solid 1px #222;
    border-radius: 50%;
    padding: 5px;
} */
/* .btn-info {
    margin-right: calc(15% + 10px);
} */
.icon-trash-o,.icon-pencil {
    font-size: 12pt;
    margin-left: 10px;
    float: right;
}
.icon-trash-o:hover,.icon-pencil:hover {
    color: #212121;
}
.p-nome {
    width: 80%;
}
.tufile {
    display: none;
}
.touch-count {
    border: 0 !important;
    color: #222 !important;
    margin-left:10px
}
.title-bar div {
    margin-right: 20px;
    float: right;
}
.delete {
    border: dashed 1px #222;
    border-radius: 10px;
    text-align: center;
    margin-top: -1px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    padding-right: 10px
}

.delete i {
    margin-left: 15px;
    margin-right: 5px;
    margin-top: 2px;
}

.scansia tr {
    position: relative;
}
.bandella {
    position: absolute;
    bottom: 5px;
    height: 17px;
    border-top: solid 1px #67646c;
    object-fit: cover;
}
.row-scansia {
    position: relative
}
.row-scansia > td {
    position: relative
}
.row-click {
    width: 15px;
    height: 63px;
    cursor: pointer;
    top: 0;
    left: -15px;
    position: absolute;
}
.row-click:hover {
    background-color: #001199;
}
</style>