var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-header" }, [
    _c("div", { staticClass: "navbar navbar-default" }, [
      _c(
        "div",
        { staticClass: "navbar-header" },
        [
          _c(
            "router-link",
            {
              staticClass: "navbar-brand navbar-brand-center",
              attrs: { to: "/" },
            },
            [_vm._v("Planoweb")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "navbar-toggleable" }, [
        _c(
          "nav",
          { staticClass: "navbar-collapse collapse", attrs: { id: "navbar" } },
          [
            _c("ul", { staticClass: "nav navbar-nav navbar-left" }, [
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/progetti" } }, [
                    _c("span", {
                      staticClass: "sidenav-icon icon icon-product-hunt",
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sidenav-label" }, [
                      _vm._v("Progetti"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/gruppi" } }, [
                    _c("span", { staticClass: "sidenav-icon icon icon-list" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sidenav-label" }, [
                      _vm._v("Gruppi"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/touchpoint" } }, [
                    _c("span", { staticClass: "sidenav-icon icon icon-th" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sidenav-label" }, [
                      _vm._v("Touch Point"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/utenti" } }, [
                    _c("span", { staticClass: "sidenav-icon icon icon-users" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sidenav-label" }, [
                      _vm._v("Utenti"),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "nav navbar-nav navbar-right" }, [
              _c("li", { staticClass: "visible-xs-block" }, [
                _c("h4", { staticClass: "navbar-text text-center" }, [
                  _vm._v(_vm._s(this.$parent.user)),
                ]),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "dropdown hidden-xs" }, [
                _c(
                  "button",
                  {
                    staticClass: "navbar-account-btn",
                    attrs: {
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.userMenu()
                      },
                    },
                  },
                  [
                    _vm._m(0),
                    _vm._v(
                      "\n                            " +
                        _vm._s(this.$parent.user) +
                        "\n                            "
                    ),
                    _c("span", { staticClass: "caret" }),
                  ]
                ),
                _vm._v(" "),
                _vm._m(1),
              ]),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "circle bg- sq-32" }, [
      _c("span", { staticClass: "icon icon-user" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      {
        staticClass: "dropdown-menu dropdown-menu-right closed",
        attrs: { id: "logutmenu" },
      },
      [
        _c("li", [
          _c("a", { attrs: { href: "/Account/Login/logout" } }, [
            _c("i", { staticClass: "icon icon-sign-out" }),
            _vm._v(" Logout\n                                "),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "visible-xs-block" }, [
      _c("a", { attrs: { href: "/logout" } }, [
        _c("i", { staticClass: "icon icon-sign-out" }),
        _vm._v(" Logout\n                        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }