var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title-bar" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-warning btn-labeled pull-right",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.newTouchPoint()
              },
            },
          },
          [_vm._m(0), _vm._v("\n              Nuovo\n          ")]
        ),
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "title-bar-title" }, [
        _vm._v("\n          Touch Point\n      "),
      ]),
      _vm._v(" "),
      _vm._m(1),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12" }, [
        _c("div", { staticClass: "panel" }, [
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                {
                  staticClass: "table table-middle nowrap",
                  attrs: { id: "dt_touchpoints" },
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { attrs: { id: "dtbody" } },
                    _vm._l(_vm.items, function (i) {
                      return _c("tr", { key: i.id }, [
                        _c("td", { attrs: { align: "center" } }, [
                          _c("img", {
                            staticClass: "grid-preview",
                            attrs: { src: i.immagine },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(i.nome))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(i.righe))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(i.colonne))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(i.gruppo != null ? i.gruppo.nome : "")),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("i", {
                            staticClass: "icon icon-edit",
                            on: {
                              click: function ($event) {
                                return _vm.edit(i.id)
                              },
                            },
                          }),
                          _vm._v(
                            "\n                                    \n                                  "
                          ),
                          _c("i", {
                            staticClass: "icon icon-remove",
                            on: {
                              click: function ($event) {
                                return _vm.remove(i.id)
                              },
                            },
                          }),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade bs-modal-lg modal-scroll",
        attrs: {
          id: "dlgFormTP",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row agutter-lg" }, [
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 md-form-group md-label-static" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.nome,
                          expression: "item.nome",
                        },
                      ],
                      staticClass: "md-form-control",
                      attrs: { type: "text", required: "" },
                      domProps: { value: _vm.item.nome },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.item, "nome", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Nome"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 md-form-group md-label-static" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.gruppi_id,
                            expression: "gruppi_id",
                          },
                        ],
                        staticClass: "md-form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.gruppi_id = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              _vm.sottogruppi_id = null
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { domProps: { value: null } }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.gruppi.filter((A) => A.gruppi_id == null),
                          function (i) {
                            return _c(
                              "option",
                              { key: i.id, domProps: { value: i.id } },
                              [_vm._v(_vm._s(i.nome))]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Gruppo"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-2 md-form-group md-label-static" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sottogruppi_id,
                            expression: "sottogruppi_id",
                          },
                        ],
                        staticClass: "md-form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.sottogruppi_id = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { domProps: { value: null } }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.gruppi.filter(
                            (A) => A.gruppi_id == _vm.gruppi_id
                          ),
                          function (i) {
                            return _vm.gruppi_id != null
                              ? _c(
                                  "option",
                                  { key: i.id, domProps: { value: i.id } },
                                  [_vm._v(_vm._s(i.nome))]
                                )
                              : _vm._e()
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Sottogruppo"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row agutter-lg" }, [
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 md-form-group md-label-static" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.righe,
                          expression: "item.righe",
                        },
                      ],
                      staticClass: "md-form-control",
                      attrs: {
                        type: "number",
                        disabled: _vm.item.righe <= 0,
                        required: "",
                      },
                      domProps: { value: _vm.item.righe },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.item, "righe", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Ripiani"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 md-form-group md-label-static" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.colonne,
                          expression: "item.colonne",
                        },
                      ],
                      staticClass: "md-form-control",
                      attrs: { type: "number", required: "" },
                      domProps: { value: _vm.item.colonne },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.item, "colonne", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Facing"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-2 md-form-group md-label-static" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.trasparente,
                            expression: "item.trasparente",
                          },
                        ],
                        staticClass: "md-form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.item,
                                "trasparente",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            () => {
                              if (_vm.item.trasparente == 2) _vm.item.righe = 0
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { domProps: { value: 0 } }, [
                          _vm._v("TouchPoint"),
                        ]),
                        _vm._v(" "),
                        _c("option", { domProps: { value: 1 } }, [
                          _vm._v("Layer"),
                        ]),
                        _vm._v(" "),
                        _c("option", { domProps: { value: 2 } }, [
                          _vm._v("Bandella"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Tipologia"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row agutter-lg" }, [
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4 md-form-group" }, [
                  _vm.item.immagine == null || _vm.item.immagine == ""
                    ? _c(
                        "label",
                        { staticClass: "file-upload-btn btn btn-primary" },
                        [
                          _c("i", { staticClass: "icon icon-image" }),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "file-upload-input",
                            attrs: {
                              type: "file",
                              name: "icon",
                              accept: "image/*",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.imageSelected($event)
                              },
                            },
                          }),
                        ]
                      )
                    : _c("div", [
                        _c("img", {
                          staticClass: "preview",
                          attrs: { src: _vm.item.immagine },
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "icon icon-4x icon-minus-circle",
                          on: {
                            click: function ($event) {
                              return _vm.imageRemove()
                            },
                          },
                        }),
                      ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.saveItem()
                    },
                  },
                },
                [_vm._v("Salva")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("Chiudi")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "btn-label" }, [
      _c("span", { staticClass: "icon icon-plus icon-lg icon-fw" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "title-bar-description" }, [
      _c("small", [
        _vm._v("Gestione touch point per la progettazione dei planogrammi"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "dt-no-sort", attrs: { width: "100px" } }, [
          _vm._v("Img"),
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Nome")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ripiani")]),
        _vm._v(" "),
        _c("th", [_vm._v("Facing")]),
        _vm._v(" "),
        _c("th", [_vm._v("Gruppo")]),
        _vm._v(" "),
        _c("th", { staticClass: "dt-no-sort" }, [
          _c("i", { staticClass: "icon icon-cog" }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("button", {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-hidden": "true",
        },
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Touch Point")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-4 md-form-group" }, [
      _c("label", [_vm._v("Immagine")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }