var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("topheader"),
      _vm._v(" "),
      _c("div", { staticClass: "layout-main" }, [
        _c("div", { staticClass: "layout-content" }, [
          _c(
            "div",
            { staticClass: "layout-content-body" },
            [_c("router-view")],
            1
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _vm.busy
        ? _c("div", { staticClass: "wait" }, [
            _c("img", { attrs: { src: "/img/loading.gif" } }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "layout-footer" }, [
      _c("div", { staticClass: "layout-footer-body" }, [
        _vm._v(
          "\n                  © BAT Planoweb 2023. All Rights Reserved.\n              "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }