<template>
  <div>
    <div class="title-bar">
        <div>
            <button class="btn btn-warning btn-labeled pull-right" type="button" @click="newProgetto()">
                <span class="btn-label">
                    <span class="icon icon-plus icon-lg icon-fw"></span>
                </span>
                Nuovo Progetto
            </button>

            <button class="btn btn-primary btn-labeled pull-right" type="button" @click="newCartella()">
                <span class="btn-label">
                    <span class="icon icon-plus icon-lg icon-fw"></span>
                </span>
                Nuova Cartella
            </button>&nbsp;
        </div>
        <h1 class="title-bar-title">
            Progetti
        </h1>
        <p class="title-bar-description">
            <small>Gestione progetti</small>
        </p>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="panel">
                <div class="panel-header">
                    <ol class="breadcrumb">
                        <li :class="currentCartella=='' ? 'active' : ''" v-on:drop="drop($event, {id: '', num_scansie: -1})" v-on:dragover="dragover($event)" v-on:dragleave="dragleave($event)">
                            <a href="#" @click="openCartella('');">Home</a>
                        </li>
                        <li v-for="p in path" :key="p.id" :class="p.id==currentCartella ? 'active' : ''" v-on:drop="drop($event, p)" v-on:dragover="dragover($event)" v-on:dragleave="dragleave($event)">
                            <a href="#" @click="openCartella(p.id)">{{p.nome}}</a>
                        </li>
                    </ol>
                </div>
                <div class="panel-body">
                    <div class="table-responsive">
                        
                        <table id="dt_progetti" class="table table-middle nowrap">
                            <thead>
                            <tr>
                                <th>Nome</th>
                                <th class="dt-no-sort">Nr. moduli</th>
                                <th>Ultima modifica</th>
                                <th>Utente</th>
                                <th class="dt-no-sort"><i class="icon icon-cog"></i></th>
                            </tr>
                            </thead>
                            <tbody id="dtbody">
                            <!-- <tr v-if="items.length==0">
                                <td colspan="3">Nessun progetto trovato</td>
                            </tr> -->
                            <tr v-for="i in items" :key="i.id">
                                <td :draggable="true" class="name" v-on:dragstart="drag($event, i, false)" v-on:drop="drop($event, i)" v-on:dragover="dragover($event)" v-on:dragleave="dragleave($event)">
                                    <router-link  v-if="i.num_scansie!=-1" :to="'/progetti/dettaglio/'+i.id+'/'+(path.length==1 ? path[0].id : 'root')">
                                        <i class="icon icon-file"></i> {{i.nome}}
                                    </router-link>
                                    <router-link v-else to="#">
                                        <div @click="openCartella(i.id)">
                                            <i class="icon icon-folder-o"></i> {{i.nome}}
                                        </div>
                                    </router-link>
                                </td>
                                <td>{{i.num_scansie>-1 ? i.num_scansie : ''}}</td>
                                <td>{{$formatDate(i.last_change)}}</td>
                                <td>{{i.user_last_change}}</td>
                                <td v-if="i.num_scansie==-1">
                                    <i @click="openCartella(i.id)" class="icon icon-folder-open-o"></i>
                                    &nbsp;&nbsp;
                                    <i v-if="i.nome!='..'" @click="renameCartella(i.id)" class="icon icon-pencil"></i>
                                    &nbsp;&nbsp;
                                    <i @click="cloneCartella(i.id)" class="icon icon-clone"></i>
                                    &nbsp;&nbsp;
                                    <i v-if="i.nome!='..'" @click="removeCartella(i.id)" class="icon icon-remove"></i>
                                </td>
                                <td v-if="i.num_scansie!=-1">
                                    <router-link :to="'/progetti/dettaglio/'+i.id+'/'+(path.length==1 ? path[0].id : 'root')">
                                        <i class="icon icon-edit"></i>
                                    </router-link>
                                    &nbsp;&nbsp;
                                    <i @click="clone(i.id)" class="icon icon-clone"></i>
                                    &nbsp;&nbsp;
                                    <i @click="remove(i.id)" class="icon icon-remove"></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return {
            path: [],
            currentCartella: '',
            dataTable: null,
            items: [],
            item: {
                id: null,
                nome: '',
                num_scansie: null
            }
        }
    },
    methods: {
        initDatatable(){
            //const data = this.items.map((A) => [A.nome,A.num_scansie,this.$formatDate(A.last_change),A.user_last_change, '<router-link :to="\'/progetti/dettaglio/\'+i.id"><i class="icon icon-edit"></i></router-link>&nbsp;&nbsp;<i @click="remove(i.id)" class="icon icon-remove"></i>']);
            if(this.dataTable==null){
                this.dataTable = $('#dt_progetti').DataTable({
                    language: this.$dt_language,
                    
                    columns: [
                        { title: "Nome"},
                        { title: "Nr.moduli", type: 'numeric-html'},
                        { title: "Ultima modifica", type: "datetime-html"},
                        { title: "Utente" },
                        { title: '<i class="icon icon-cog"></i>' }
                    ],
                    order: [[2,'desc']]
                });
            } else {
                // this.dataTable.destroy();
                this.$forceUpdate();
                this.$nextTick(() => this.dataTable = $('#dt_progetti').DataTable({
                    language: this.$dt_language,
                    //data: data,
                    columns: [
                        { title: "Nome"},
                        { title: "Nr.moduli", type: "numeric-html"},
                        { title: "Ultima modifica", type: "datetime-html"},
                        { title: "Utente" },
                        { title: '<i class="icon icon-cog"></i>' }
                    ],
                    order: [[2,'desc']]
                }));
            }
            // if(this.dataTable==null){
            //     this.dataTable = $('#dt_progetti').DataTable({
            //         language: this.$dt_language
            //     });
            // } else {
            //     this.dataTable.destroy();
            //     this.$nextTick(() => this.dataTable = $('#dt_progetti').DataTable({
            //         language: this.$dt_language
            //     }));
            // }
        },
        newProgetto(){
            // this.item = {
            //     id: null,
            //     nome: '',
            //     num_scansie: null
            // };
            // this.$showModal('dlgForm');
            // this.$router.push('/progetti/dettaglio');
            location.href='/progetti/dettaglio/0/'+(this.path!=null && this.path.length==1 ? this.path[0].id : 'root');
        },
        newCartella(){
            Swal.fire({
                title: 'Inserire il nome della cartella',
                showCancelButton: true,
                confirmButtonText: 'Crea cartella',
                showLoaderOnConfirm: true,
                input: 'text',
                preConfirm: (nome) => {
                    return fetch('/progetti/creacartella', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({current: this.currentCartella, nome: nome})
                    }).then(response => {
                            if (!response.ok) {
                                throw new Error(response.statusText)
                            } else {
                                this.dataTable.destroy();
                                this.loadProjects();
                            }
                        }).catch(error => {
                            //Swal.showValidationMessage('Inserire il nome della cartella');
                            Swal.showValidationMessage(error);
                        });
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                
            })
        },
        remove(id){
            Swal.fire({
                title: 'Vuoi eliminare il progetto?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie',
            }).then((result) => {
                if (result.value) {
                    fetch('/progetti/delete/' + id, {
                        method: 'DELETE',
                    }).then(res => {
                        if(res.ok){
                            this.dataTable.destroy();
                            const ix = this.items.findIndex(A => A.id==id);
                            this.items.splice(ix, 1);
                            this.$forceUpdate();
                            this.$nextTick(() => this.initDatatable());
                        } else {
                            toastr.error(res.statusText, 'Errore');
                        }
                    }).catch(err => {
                        toastr.error(err, 'Errore');
                    })
                }
            })
        },
        clone(id){
            Swal.fire({
                title: 'Vuoi duplicare il progetto?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie',
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Inserire il nome del nuovo progetto',
                        showCancelButton: true,
                        confirmButtonText: 'Duplica',
                        showLoaderOnConfirm: true,
                        input: 'text',
                        preConfirm: (nome) => {
                            return fetch('/progetti/clone', {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({id: id, nome: nome})
                            }).then(res => {
                                if(res.ok){
                                    this.dataTable.destroy();
                                    this.loadProjects();
                                } else {
                                    toastr.error(res.statusText, 'Errore');
                                }
                            }).catch(err => {
                                toastr.error(err, 'Errore');
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                        
                    });
                }
            })
        },
        loadProjects(){
            let url = '/progetti/progettiecartelle';
            
            if(this.currentCartella!=''){
                url += '/' + this.currentCartella;
            }

            fetch(url).then(res => {
                res.json().then(r => { 
                    this.items = r.list;
                    this.path = r.path;
                    if(this.currentCartella!=''){
                        let ix = this.path.findIndex(A => A.id==this.currentCartella);
                        let levelUp = '';
                        if(ix>0){
                            levelUp = this.path[ix-1].id;
                        }
                        this.items.unshift({id: levelUp, nome: '..', num_scansie: -1, last_change: '', user_last_change: ''});
                    }
                    this.$nextTick(() => this.initDatatable());
                });
            });
        },
        openCartella(id){
            this.dataTable.destroy();
            this.currentCartella = id;
            this.loadProjects();
        },
        renameCartella(id){
            Swal.fire({
                title: 'Inserire il nuovo nome della cartella',
                showCancelButton: true,
                confirmButtonText: 'Rinomina cartella',
                showLoaderOnConfirm: true,
                input: 'text',
                preConfirm: (nome) => {
                    return fetch('/progetti/renamecartella', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({id: id, nome: nome})
                    }).then(response => {
                            if (!response.ok) {
                                throw new Error(response.statusText)
                            } else {
                                this.dataTable.destroy();
                                this.loadProjects();
                            }
                        }).catch(error => {
                            //Swal.showValidationMessage('Inserire il nome della cartella');
                            Swal.showValidationMessage(error);
                        });
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                
            })
        },
        cloneCartella(id){
            Swal.fire({
                title: 'Vuoi duplicare la cartella e tutti i progetti contenuti?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie',
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Inserire il nome della nuova cartella',
                        showCancelButton: true,
                        confirmButtonText: 'Duplica',
                        showLoaderOnConfirm: true,
                        input: 'text',
                        preConfirm: (nome) => {
                            return fetch('/progetti/clonecartella', {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({id: id, nome: nome})
                            }).then(res => {
                                if(res.ok){
                                    this.dataTable.destroy();
                                    this.loadProjects();
                                } else {
                                    toastr.error(res.statusText, 'Errore');
                                }
                            }).catch(err => {
                                toastr.error(err, 'Errore');
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                        
                    });
                }
            })
        },
        removeCartella(id){
            Swal.fire({
                title: 'Vuoi eliminare la carella?',
                text: 'Tutti i progetti contenuti saranno spostati fuori cartella.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie',
            }).then((result) => {
                if (result.value) {
                    fetch('/progetti/deletecartella/' + id, {
                        method: 'DELETE',
                    }).then(res => {
                        if(res.ok){
                            this.dataTable.destroy();
                            this.loadProjects();
                        } else {
                            toastr.error(res.statusText, 'Errore');
                        }
                    }).catch(err => {
                        toastr.error(err, 'Errore');
                    })
                }
            })
        },
        drag(ev, t) {
            ev.dataTransfer.setData("data", JSON.stringify(t));
        },
        dragover(ev) {
           ev.preventDefault();
           $(ev.target).addClass('dragging');
        },
        dragleave(ev){
            $(ev.target).removeClass('dragging');
        },
        drop(ev, c) {
            ev.preventDefault();
            const data = JSON.parse(ev.dataTransfer.getData("data"));
            $(ev.target).removeClass('dragging');
            if(!data) return;
            if(c.num_scansie!=-1) return;

            fetch('/progetti/move', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({element_id: data.id, cartella_id: c.id, cartella: data.num_scansie==-1})
            }).then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                } else {
                    this.dataTable.destroy();
                    this.loadProjects();
                }
            }).catch(error => {
                toastr.error(error, 'Errore');
            });
        },
        
    },
    mounted(){
        console.info('Progetto mounted');
        this.currentCartella = this.$route.params.path!=null ? this.$route.params.path : '';
        this.loadProjects(); 
        this.$initApp();
        this.$initElephant();
    }
}
</script>

<style scoped>
.btn-primary {
    margin-right: 10px;
}
.name .icon {
    font-size: larger;
}
</style>